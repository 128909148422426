import { Index2 } from './index.html';

let node_env = process.env.NODE_ENV;
// switch(node_env){
//   case "production":
//     console.log('Looks like we are in production mode!');
//   break;
//   case "pre":
//     console.log('Looks like we are in pre mode!');
//   break;
//   case "test":
//     console.log('Looks like we are in test mode!');
//   break;
//   case "development":
//     console.log('Looks like we are in development mode!');
//     break;
//   default:
//     console.log(node_env);
//   break;
// }
sessionStorage.setItem("node_env",node_env);

function component() {
  var element = document.createElement('div');

  // element.innerHTML = [
  //     'Hello webpack!',
  //     '5 cubed is equal to ' + cube(5)
  //   ].join('\n\n');

  element.innerHTML = Index2;

  return element;
}

document.body.appendChild(component());